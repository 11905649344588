
import NewProjectReport from "@/components/admin/NewProjectReport.vue";
import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";
import { Getters } from "@/store/admin/adminGetters";
import { Action, Getter } from "vuex-class";
import { systemAPI } from "@/lib/s3PlatformApi";
import { V2Assessment, V2Project, V2Benchmark, ProjectReportData } from "@/store/admin/adminTypes";
import { Actions } from "@/store/admin/adminActions";

const namespace = "admin";

@Component({
  components: {
    NewProjectReport,
  },
})
export default class CreateTeamEffectivenessReport extends TypedVue {
  public loading = true;
  public report: ProjectReportData | null = null;
  public assessments: V2Assessment[] = [];
  public benchmarks: V2Benchmark[] = [];

  @Getter(Getters.GET_CURRENT_PROJECT, { namespace })
  public currentProject!: V2Project | null;

  @Action(Actions.GET_PROJECT_REPORT_DATA, { namespace })
  getProjectReportData!: ({ reportId, raw }: { reportId: string; raw?: boolean }) => Promise<ProjectReportData>;

  get reportId() {
    return this.$route.params.id;
  }

  async created() {
    if (this.currentProject) {
      this.report = await this.getProjectReportData({ reportId: this.reportId, raw: true });
      await this.getAssessments();
      await this.getBenchmarks(this.currentProject.identifier);
      this.loading = false;
    }
  }

  async getAssessments() {
    this.assessments = (await systemAPI.getAssessments({ include_scores: true, project: this.currentProject?.identifier })).data;
  }

  async getBenchmarks(projectId: string) {
    this.benchmarks = (await systemAPI.getBenchmarks({ project_id: projectId })).data;
  }
}
