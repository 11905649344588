
import { TypedVue } from "@/store/types";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Project, Assessment, Benchmark, ProjectReportType, ProjectReport } from "@/store/internal/internalTypes";
import { TRAITS } from "@/lib/consts";
import ComparisonRadarChartComponent, { ComparisonAssessmentItem, ComparisonBenchmarkItem, ComparisonChartPreset } from "@/components/admin/ComparisonComponents/ComparisonRadarChartComponent.vue";
import { AssessmentState, CreateProjectReport, CreateReport, SchemasCandidateAssessment, SchemasV2InternalAssessmentsScoring } from "@/lib/serviceTypes";
import { Benchmark as AdminSchemaBenchmark, SearchReportData } from "@/store/admin/adminTypes";
import { ColorMap } from "@/lib/charts/HighChartThemes";
import Vue from "vue";
import { Action } from "vuex-class";
import { Actions } from "@/store/internal/interalActions";

import MarkdownEditor from "../input/MarkdownEditor.vue";

@Component({
  components: {
    ComparisonRadarChartComponent,
    MarkdownEditor,
  },
})
export default class EditProjectSearchReport extends TypedVue {
  @Prop()
  project!: Project;

  @Prop()
  assessments!: Assessment[];

  @Prop()
  benchmarks!: Benchmark[];

  @Prop({ default: null })
  initialReport!: {
    identifier: string;
    name: string;
    description: string;
    version: string;
    type: ProjectReportType;
    scoringVersion: string;
    assessmentIds: string[];
    data: SearchReportData;
  } | null;

  @Prop({ default: false })
  clientView!: boolean;

  @Action(Actions.SAVE_PROJECT_REPORT, { namespace: "internal" })
  saveProjectReport!: (reportArgs: { projectId: string; projectReport: CreateReport }) => Promise<string>;

  @Action(Actions.SAVE_PROJECT_REPORT, { namespace: "admin" })
  systemSaveProjectReport!: (reportArgs: { projectId: string; projectReport: CreateProjectReport }) => Promise<string>;

  @Action(Actions.UPDATE_REPORT, { namespace: "internal" })
  updateProjectReport!: (data: { reportId: string; name: string; description: string | undefined; assessmentIds: string[]; data: Record<string, unknown> }) => Promise<void>;

  @Action(Actions.DELETE_REPORT, { namespace: "internal" })
  deleteReport!: (report_id: string) => Promise<ProjectReport>;

  reportName = "";
  reportDescription = "";
  reportVersion = "1.0";
  scoringVerison = "v2";
  reportType: ProjectReportType = "search-report";
  // selectedAssessmentIds: string[] = [];
  candidateSelectedState: Record<string, boolean> = {};

  reportOverviewCustomText = "";
  teamMatrixMustHaveCustomText = "";
  teamMatrixTopPriorityCustomText = "";

  traitPriorities: { [trait: string]: number } = {};
  traitWeights: { [trait: string]: number } = {};
  participantTitles: { [key: string]: string } = {};
  tags: Record<string, string[]> = {};
  demoMode = false;

  bonusInsights = "";
  candidateInsights: Record<string, string> = {};

  allAssessmentsSelected = false;

  generatedReportId: null | string = null;

  compariableBenchmarks = Vue.observable<ComparisonBenchmarkItem[]>([]);
  compariableAssessments = Vue.observable<ComparisonAssessmentItem[]>([]);
  comparisonPresets = Vue.observable<ComparisonChartPreset[]>([]);

  searchText = "";
  editMode = false;
  traitRankChoices = [
    { value: 0, text: "Neutral" },
    { value: 1, text: "Nice to Have" },
    { value: 2, text: "Must Have" },
  ];
  weightChoices = [
    { value: 1, text: "Least Important" },
    { value: 2, text: "Less Important" },
    { value: 3, text: "Equally Important" },
    { value: 4, text: "More Important" },
    { value: 5, text: "Most Important" },
  ];
  titleChoices = [
    { value: "candidate", text: "Candidate" },
    { value: "team", text: "Team" },
    { value: "manager", text: "Manager" },
  ];

  @Watch("selectedAssessmentIds")
  onSelectedAssessmentIdsChange(newValue: string[], oldValue: string[]) {
    this.allAssessmentsSelected = newValue.length === this.assessments.length;
    this.setComparableAssessments();

    if (newValue.length < oldValue.length) {
      const removedAssessmentIds = oldValue.filter((x) => !newValue.includes(x));
      for (const removedAssessmentId of removedAssessmentIds) {
        for (const preset of this.comparisonPresets) {
          preset.items = preset.items.filter((x) => x.id !== removedAssessmentId);
        }
      }
    }
  }

  get assessmentsInProject() {
    return this.validAssessments.filter((assessment) => assessment.projectId === this.project.identifier);
  }

  get assessmentsNotInProject() {
    return this.validAssessments.filter((assessment) => assessment.projectId !== this.project.identifier);
  }

  get validAssessments() {
    return this.assessments.filter((assessment) => {
      if (!assessment.report) {
        return false;
      }
      if (!assessment.scores?.find((x) => x.scoringVersion === this.scoringVerison)) {
        return false;
      }
      if (this.searchText) {
        return assessment.candidate.name.toLowerCase().includes(this.searchText.toLowerCase()) || assessment.identifier.slice(0, 4).toLowerCase().includes(this.searchText.toLowerCase());
      }
      return true;
    });
  }

  get assesmentDisplayList() {
    return this.assessments
      .filter((assessment) => assessment.scores?.find((x) => x.scoringVersion === this.scoringVerison))
      .map((assessment) => ({
        id: assessment.identifier,
        name: `${assessment.identifier.substring(0, 4)} - ${assessment.candidate.name}`,
      }));
  }

  get selectedAssessmentIds() {
    return Object.keys(this.candidateSelectedState).filter((key) => this.candidateSelectedState[key]);
  }

  set selectedAssessmentIds(value: string[]) {
    this.candidateSelectedState = this.assessments.reduce((acc, assessment) => {
      acc[assessment.identifier] = value.includes(assessment.identifier);
      return acc;
    }, {} as Record<string, boolean>);

    this.participantTitles = this.assessments.reduce((acc, assessment) => {
      if (this.initialReport?.data.managers.includes(assessment.identifier)) {
        acc[assessment.identifier] = "manager";
      } else if (this.initialReport?.data.team.includes(assessment.identifier)) {
        acc[assessment.identifier] = "team";
      } else {
        acc[assessment.identifier] = "candidate";
      }
      return acc;
    }, {} as Record<string, string>);
  }

  get selectedCandidates() {
    return this.selectedAssessmentIds.filter((id) => this.participantTitles[id] === "candidate");
  }

  public setComparableAssessments() {
    this.compariableAssessments = this.assessments
      .filter((assessment) => this.selectedAssessmentIds.includes(assessment.identifier))
      .filter((assessment) => assessment.scores?.find((x) => x.scoringVersion === "v2"))
      .map((assessment) => ({ assessment, scoring: assessment.scores?.find((x) => x.scoringVersion === "v2") as SchemasV2InternalAssessmentsScoring }))
      .map(({ assessment, scoring }) => ({
        id: assessment.identifier,
        name: `${assessment.identifier.substring(0, 4)} - ${assessment.candidate.name}`,
        hidden: false,
        isLocked: false,
        selected: false,
        color: ColorMap[this.project.theme][scoring.readiness],
        initialColor: ColorMap[this.project.theme][scoring.readiness],
        data: this.mapAssessmentToSchemaAssessment(assessment, scoring),
      }));
  }

  mounted() {
    if (this.initialReport) {
      if (this.initialReport.type !== this.reportType) {
        throw new Error("Only search-reports reports are supported");
      }
      this.editMode = true;
      this.reportName = this.initialReport.name;
      this.reportDescription = this.initialReport.description;
      this.reportVersion = this.initialReport.version;
      this.selectedAssessmentIds = this.initialReport.assessmentIds;
      this.traitPriorities = this.initialReport.data.traitPriorities;
      this.traitWeights = this.initialReport.data.traitWeights;
      this.tags = this.initialReport.data.tags;
      this.comparisonPresets = this.initialReport.data.comparisonPresets;
      this.bonusInsights = this.initialReport.data.customText.bonusInsights;
      this.candidateInsights = this.initialReport.data.customText.candidates;
      this.reportOverviewCustomText = this.initialReport.data.customText.reportOverviewCustomText;
      this.demoMode = this.initialReport.data.demoMode || false;
    } else {
      this.reportName = `${this.project.name} Search Report`;
      this.selectedAssessmentIds = [];
      this.traitPriorities = TRAITS.reduce((acc, trait) => {
        acc[trait] = 0;
        return acc;
      }, {} as { [trait: string]: number });
      this.traitWeights = TRAITS.reduce((acc, trait) => {
        acc[trait] = 0;
        return acc;
      }, {} as { [trait: string]: number });
    }

    this.compariableBenchmarks = this.benchmarks.map((benchmark) => ({
      id: benchmark.identifier,
      name: benchmark.name,
      hidden: false,
      isLocked: false,
      selected: benchmark.default || false,
      color: benchmark.colour,
      initialColor: benchmark.colour,
      data: this.mapBenchmarkToSchemaBenchmark(benchmark),
    }));
    this.setComparableAssessments();
  }

  async saveReport() {
    if (this.editMode) {
      await this.updateProjectReport({
        reportId: this.initialReport?.identifier || "",
        name: this.reportName,
        description: this.reportDescription,
        assessmentIds: this.selectedAssessmentIds,
        data: this.createReportData(),
      });
      this.generatedReportId = this.initialReport?.identifier || "";
    } else {
      const projectReport = {
        name: this.reportName,
        description: this.reportDescription,
        reportVersion: this.reportVersion,
        reportType: this.reportType,
        scoringVersion: this.scoringVerison,
        assessmentIds: this.selectedAssessmentIds,
        data: this.createReportData(),
      };
      if (this.clientView) {
        this.generatedReportId = await this.systemSaveProjectReport({
          projectId: this.project.identifier,
          projectReport,
        });
      } else {
        this.generatedReportId = await this.saveProjectReport({
          projectId: this.project.identifier,
          projectReport,
        });
      }
    }
  }

  public async deleteProjectReport() {
    if (confirm("Are you sure you want to delete this report?")) {
      await this.deleteReport(this.initialReport?.identifier || "");
      await this.$router.push("/admin/summary");
    }
  }

  createReportData() {
    // Cast strings to numbers
    const traitWeights = Object.entries(this.traitWeights).reduce((acc, [trait, weight]) => {
      acc[trait] = +weight;
      return acc;
    }, {} as Record<string, number>);

    return {
      traitPriorities: this.traitPriorities,
      traitWeights: traitWeights,
      candidates: Object.entries(this.participantTitles)
        .filter(([key, value]) => this.selectedAssessmentIds.includes(key) && value === "candidate")
        .map((x) => x[0]),
      team: Object.entries(this.participantTitles)
        .filter(([key, value]) => this.selectedAssessmentIds.includes(key) && value === "team")
        .map((x) => x[0]),
      managers: Object.entries(this.participantTitles)
        .filter(([key, value]) => this.selectedAssessmentIds.includes(key) && value === "manager")
        .map((x) => x[0]),
      tags: Object.keys(this.tags).reduce((acc, assessment) => {
        if (this.selectedAssessmentIds.includes(assessment)) {
          acc[assessment] = this.tags[assessment];
        }
        return acc;
      }, {} as Record<string, string[]>),
      comparisonPresets: this.comparisonPresets,
      customText: {
        bonusInsights: this.bonusInsights,
        candidates: this.candidateInsights,
        teamMatrixMustHaveCustomText: this.teamMatrixMustHaveCustomText,
        teamMatrixTopPriorityCustomText: this.teamMatrixTopPriorityCustomText,
        reportOverviewCustomText: this.reportOverviewCustomText,
      },
      demoMode: this.demoMode,
    };
  }

  removePreset(preset: ComparisonChartPreset) {
    const index = this.comparisonPresets.findIndex((x) => x.id === preset.id);
    if (index !== -1) {
      this.comparisonPresets.splice(index, 1);
    }
  }

  savePreset(preset: ComparisonChartPreset) {
    this.comparisonPresets.push(preset);
  }

  updatePreset(preset: ComparisonChartPreset) {
    const index = this.comparisonPresets.findIndex((x) => x.id === preset.id);
    if (index !== -1) {
      this.comparisonPresets.splice(index, 1, preset);
    }
  }

  mapAssessmentToSchemaAssessment(assessment: Assessment, score: SchemasV2InternalAssessmentsScoring): SchemasCandidateAssessment {
    return {
      assessmentId: assessment.identifier,
      candidateName: assessment.candidate.name,
      isComplete: assessment.state === AssessmentState.ScoringComplete,
      project: assessment.projectId,
      organization: assessment.company,
      competencyScores: score.competencyScores,
      competencies: score.competencies,
      traitScores: score.traitScores,
      readinessScore: score.readiness,
    }; // TODO: We need to migrate the comparison radar chart to use the new schema and we can remove this
  }

  mapBenchmarkToSchemaBenchmark(benchmark: Benchmark): AdminSchemaBenchmark {
    return {
      alwaysShow: benchmark.default,
      benchmarkId: benchmark.identifier,
      colour: benchmark.colour,
      name: benchmark.name,
      competencyScores: benchmark.competencyScores,
      isAdmin: benchmark.isAdmin,
      organization: benchmark.orgId,
      traitScores: benchmark.traitScores,
      traitRanges: benchmark.traitRanges,
      dateCreated: "",
      lastUpdated: "",
    }; // TODO: We need to migrate the comparison radar chart to use the new schema and we can remove this
  }

  getCandidateTitle(candidateId: string) {
    const assessment = this.assessments.find((x) => x.identifier === candidateId);
    if (assessment && this.clientView) {
      return assessment.candidate.name;
    }
    return candidateId.slice(0, 4);
  }
}
