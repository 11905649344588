
import { Component, Emit, Prop, Ref } from "vue-property-decorator";
import { TypedVue } from "@/store/types";
import { TRAIT_CLASS_RANGES, Trait } from "@/lib/consts";
import { V2AssessmentWithScoring } from "@/store/admin/adminTypes";
import { getMatchScore, getSimilarityScoreToGroup, getSimilarityScoreToSingle } from "@/lib/scoring";
import CandidateTag from "@/components/platform/CandidateTag.vue";
import MarkdownIt from "markdown-it";

export type TableView = "rankings" | "scores";
const md = new MarkdownIt({ html: true });

@Component({ components: { CandidateTag } })
export default class CandidateSearchTable extends TypedVue {
  @Prop() traitPriorities!: Record<string, 0 | 2 | 1>;
  @Prop() traitWeights!: Record<string, number>;
  @Prop() candidateAssessments!: V2AssessmentWithScoring[];
  @Prop({ default: null }) managerAssessment!: V2AssessmentWithScoring | null;
  @Prop() teamAssessments!: V2AssessmentWithScoring[];
  @Prop() showNames!: boolean;
  @Prop({ default: false }) showAllCandidates!: boolean;
  @Prop({ default: false }) demoMode!: boolean;
  @Prop({ default: "rankings" }) view!: "rankings" | "scores";
  @Prop({ default: false }) showCompetencyBreakdown!: boolean;
  @Prop({
    default: () => {
      return {};
    },
  })
  candidateInsights!: Record<string, string>;
  @Prop({
    default: () => {
      return {};
    },
  })
  tags!: Record<string, string[]>;

  @Emit()
  isExpanded(value: boolean) {
    this.expanded = value;
  }

  @Ref("candidate-table") table!: HTMLTableElement;

  fullInsights: Record<string, boolean> = {};
  expanded = false;
  sortField = "ranking";
  sortDesc = true;

  get orderedAssessments() {
    let candidates = this.candidateAssessments.slice();
    if (this.showAllCandidates) {
      // candidates = candidates.concat(this.teamAssessments.slice());
      candidates = candidates.concat(this.managerAssessment ? [this.managerAssessment] : []);
    }

    const assessments = candidates.sort((a, b) => {
      if (this.sortField === "ranking") {
        if (this.sortAssessments(this.sortField, a, b) !== 0) {
          return this.sortAssessments(this.sortField, a, b);
        } else if (this.sortAssessments("must-haves", a, b) !== 0) {
          return this.sortAssessments("must-haves", a, b);
        } else {
          return this.sortAssessments("nice-to-haves", a, b);
        }
      } else {
        return this.sortAssessments(this.sortField, a, b);
      }
    });

    if (this.sortDesc) return assessments;
    else return assessments.reverse();
  }

  get mustHavesCount() {
    return Object.keys(this.traitPriorities).filter((trait) => this.traitPriorities[trait] === 2).length;
  }

  get niceToHavesCount() {
    return Object.keys(this.traitPriorities).filter((trait) => this.traitPriorities[trait] === 1).length;
  }

  get mustHaves() {
    return Object.keys(this.traitPriorities)
      .filter((trait) => this.traitPriorities[trait] === 2)
      .sort((a, b) => this.traitWeights[b] - this.traitWeights[a]);
  }

  get niceToHaves() {
    return Object.keys(this.traitPriorities)
      .filter((trait) => this.traitPriorities[trait] === 1)
      .sort((a, b) => this.traitWeights[b] - this.traitWeights[a]);
  }

  get showAdditionalInsights() {
    return Object.values(this.candidateInsights).some((c) => c.length > 0);
  }

  mounted() {
    for (const assessment of this.candidateAssessments) {
      this.$set(this.fullInsights, assessment.identifier, false);
    }
  }

  setSort(sort: string) {
    if (this.sortField === sort) {
      this.sortDesc = !this.sortDesc;
    } else {
      this.sortField = sort;
      this.sortDesc = true;
    }
  }

  sortAssessments(sortField: string, a: V2AssessmentWithScoring, b: V2AssessmentWithScoring) {
    switch (sortField) {
      case "team":
        return this.calculateTeamSimilarity(b) - this.calculateTeamSimilarity(a);
      case "manager":
        return this.calculateManagerSimilarity(b) - this.calculateManagerSimilarity(a);
      case "nice-to-haves":
        if (this.getTraitsPassedByPriority(b, 1) - this.getTraitsPassedByPriority(a, 1) === 0) {
          return this.getTraitsPrioritizedByPriority(b, 1) - this.getTraitsPrioritizedByPriority(a, 1);
        }
        return this.getTraitsPassedByPriority(b, 1) - this.getTraitsPassedByPriority(a, 1);
      case "must-haves":
        if (this.getTraitsPassedByPriority(b, 2) - this.getTraitsPassedByPriority(a, 2) === 0) {
          return this.getTraitsPrioritizedByPriority(b, 2) - this.getTraitsPrioritizedByPriority(a, 2);
        }
        return this.getTraitsPassedByPriority(b, 2) - this.getTraitsPassedByPriority(a, 2);
      case "candidate":
        return a.candidate.name.localeCompare(b.candidate.name);
      case "ranking":
      default:
        return Math.round(this.calculateMatchScore(b)) - Math.round(this.calculateMatchScore(a));
    }
  }

  getCandidateIndex(assessment: V2AssessmentWithScoring) {
    let candidates = this.candidateAssessments.slice();
    if (this.showAllCandidates) {
      // candidates = candidates.concat(this.teamAssessments.slice());
      candidates = candidates.concat(this.managerAssessment ? [this.managerAssessment] : []);
    }

    const order = candidates.slice().sort((a, b) => this.calculateMatchScore(b) - this.calculateMatchScore(a));
    return order.findIndex((a) => a.identifier === assessment.identifier) + 1;
  }

  getTags(assessment: V2AssessmentWithScoring) {
    return (this.tags[assessment.identifier] ?? [])
      .slice()
      .sort()
      .filter((tag) => tag !== "candidate");
  }

  getAdditionalInsights(assessment: V2AssessmentWithScoring) {
    if (this.fullInsights[assessment.identifier]) return this.candidateInsights[assessment.identifier] ?? "";
    else return (this.candidateInsights[assessment.identifier] ?? "").substring(0, 140) + ((this.candidateInsights[assessment.identifier] ?? "").length > 140 ? "..." : "");
  }

  getTraitsPassedByPriority(assessment: V2AssessmentWithScoring, priority: 0 | 1 | 2): number {
    return Object.keys(assessment.scoring.trait_scores as Record<string, number>).filter(
      (trait) => this.traitPriorities[trait] === priority && (assessment.scoring.trait_scores as any)[trait] >= TRAIT_CLASS_RANGES[trait as Trait].low,
    ).length;
  }

  getTraitsPrioritizedByPriority(assessment: V2AssessmentWithScoring, priority: 0 | 1 | 2): number {
    return Object.keys(assessment.scoring.trait_scores as Record<string, number>).filter(
      (trait) => this.traitPriorities[trait] === priority && (assessment.scoring.trait_scores as any)[trait] >= TRAIT_CLASS_RANGES[trait as Trait].high,
    ).length;
  }

  getCompetencyClass(trait: string, assessment: V2AssessmentWithScoring) {
    if ((assessment.scoring.trait_scores as any)[trait] >= TRAIT_CLASS_RANGES[trait as Trait].high) return "prioritized";
    if ((assessment.scoring.trait_scores as any)[trait] >= TRAIT_CLASS_RANGES[trait as Trait].low) return "passed";
  }

  calculateMatchScore(assessment: V2AssessmentWithScoring): number {
    return getMatchScore(assessment.scoring.trait_scores as Record<string, number>, this.traitPriorities, this.traitWeights);
  }

  calculateManagerSimilarity(assessment: V2AssessmentWithScoring): number {
    if (this.managerAssessment === null) return 0;

    return Math.round(getSimilarityScoreToSingle(assessment.scoring.trait_scores as Record<string, number>, this.managerAssessment.scoring.trait_scores as Record<string, number>));
  }

  calculateTeamSimilarity(assessment: V2AssessmentWithScoring): number {
    if (this.teamAssessments.length < 2) return 0;

    return Math.round(
      getSimilarityScoreToGroup(
        assessment.scoring.trait_scores as Record<string, number>,
        this.teamAssessments.map((a) => a.scoring.trait_scores as Record<string, number>),
      ),
    );
  }

  setFullInsights(assessment: V2AssessmentWithScoring, value: boolean) {
    this.$set(this.fullInsights, assessment.identifier, value);

    if (Object.values(this.fullInsights).every((v) => v)) this.isExpanded(true);
    else this.isExpanded(false);
  }

  toggleExpanded() {
    if (this.expanded) {
      for (const key of Object.keys(this.fullInsights)) {
        this.$set(this.fullInsights, key, false);
      }
    } else {
      for (const key of Object.keys(this.fullInsights)) {
        this.$set(this.fullInsights, key, true);
      }
    }
    this.isExpanded(!this.expanded);
  }

  renderMarkdown(text: string) {
    return md.render(text);
  }
}
