
import NewProjectReport from "@/components/admin/NewProjectReport.vue";
import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";
import { Getters } from "@/store/admin/adminGetters";
import { Getter } from "vuex-class";
import { systemAPI } from "@/lib/s3PlatformApi";
import { V2Assessment, V2Project, V2Benchmark } from "@/store/admin/adminTypes";

const namespace = "admin";

@Component({
  components: {
    NewProjectReport,
  },
})
export default class CreateTeamEffectivenessReport extends TypedVue {
  public loading = true;
  public assessments: V2Assessment[] = [];
  public benchmarks: V2Benchmark[] = [];

  @Getter(Getters.GET_CURRENT_PROJECT, { namespace })
  public currentProject!: V2Project | null;

  async created() {
    if (this.currentProject) {
      await this.getAssessments();
      await this.getBenchmarks(this.currentProject.identifier);
      this.loading = false;
    }
  }

  async getAssessments() {
    this.assessments = (await systemAPI.getAssessments({ include_scores: true, project: this.currentProject?.identifier })).data;
  }

  async getBenchmarks(projectId: string) {
    this.benchmarks = (await systemAPI.getBenchmarks({ project_id: projectId })).data;
  }
}
