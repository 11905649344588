import { ActionTree } from "vuex";
import { Benchmark, Project, ProjectTheme, StoreState, V2Project } from "./adminTypes";
import { RootState } from "../types";
import { Mutations } from "./adminMutations";
import { systemAPI } from "@/lib/s3PlatformApi";
import { getResourceByVersion } from "@/lib/resources";
import adminService from "@/services/adminService";
import assessmentService from "@/services/assessmentService";
import { CreateProjectReport } from "@/lib/serviceTypes";

export enum Actions {
  CHANGE_CURRENT_THEME = "CHANGE_CURRENT_THEME",
  CHANGE_PROJECT_BENCHMARKS = "CHANGE_PROJECT_BENCHMARKS",
  CHANGE_PROJECT = "CHANGE_PROJECT",
  LOAD_PROJECTS = "LOAD_PROJECTS",
  SET_PROJECT_LOADING = "SET_PROJECT_LOADING",
  SET_PROJECT_LOADING_FALSE = "SET_PROJECT_LOADING_FALSE",
  GET_PROJECT_THEME = "GET_PROJECT_THEME",
  GET_PROJECT_ASSESSMENTS = "GET_PROJECT_ASSESSMENTS",
  GET_REPORT = "GET_REPORT",
  GET_LITERALS = "GET_LITERALS",
  GET_ORG_PROJECTS = "GET_ORG_PROJECTS",
  GET_PROJECT_AGGREGATES = "GET_PROJECT_AGGREGATES",
  GET_PROJECT_BENCHMARKS = "GET_PROJECT_BENCHMARKS",
  GET_PROJECT_REPORT_DATA = "GET_PROJECT_REPORT_DATA",
  GET_CANDIDATE_ASSESSMENT_PROJECT = "GET_CANDIDATE_ASSESSMENT_PROJECT",
  GET_CANDIDATES_ASSESSMENT = "GET_CANDIDATES_ASSESSMENT",
  GET_TEMPLATE = "GET_TEMPLATE",
  SAVE_TEMPLATE = "SAVE_TEMPLATE",
  GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID",
  SAVE_PROJECT_REPORT = "SAVE_PROJECT_REPORT",
}

export const actions: ActionTree<StoreState, RootState> = {
  [Actions.GET_PROJECT_REPORT_DATA]: async (_, { reportId, raw = false }: { reportId: string; raw?: boolean }) => {
    return (await systemAPI.getReport(reportId, { raw })).data;
  },

  async [Actions.GET_TEMPLATE](_, templateName: string) {
    return assessmentService.getTemplate(templateName);
  },

  async [Actions.SAVE_TEMPLATE](_, { name, originId, template, isCustom = true }: { name: string; originId: string; template: string; isCustom: boolean }) {
    return assessmentService.saveTemplate(name, originId, template, isCustom);
  },

  async [Actions.CHANGE_CURRENT_THEME]({ commit, state }, theme: ProjectTheme) {
    if (state.currentProject && state.currentProject.theme !== theme) {
      const benchmarkIds = state.projectBenchmarks[state.currentProject.identifier].map((b) => b.benchmarkId);
      await adminService.updateProject({ projectId: state.currentProject.identifier, theme, benchmarks: benchmarkIds } as Project).catch((err) => console.error(err));
      commit(Mutations.SET_PROJECT_THEME, {
        projectId: state.currentProject.identifier,
        theme,
      });
    }
  },
  async [Actions.CHANGE_PROJECT_BENCHMARKS]({ commit, state }, benchmarks: Benchmark[]) {
    if (state.currentProject && state.currentProject.identifier !== "" && state.projectBenchmarks[state.currentProject.identifier] !== benchmarks) {
      const benchmarkIds = benchmarks.map((b) => b.benchmarkId);
      await adminService.updateProject({ projectId: state.currentProject.identifier, benchmarks: benchmarkIds, theme: state.projectThemes[state.currentProject.identifier] } as Project).catch((err) => console.error(err));
      commit(Mutations.SET_PROJECT_BENCHMARKS, {
        projectId: state.currentProject.identifier,
        benchmarks,
      });
    }
  },
  async [Actions.CHANGE_PROJECT]({ commit, dispatch }, project: V2Project | null) {
    let newProject = project;
    if (!project) {
      newProject = {
        identifier: "",
        name: "Projects",
        description: "All",
        dateCreated: "",
        orgId: "",
        orgChartId: "",
        theme: ProjectTheme.DEFAULT_TA,
        features: [],
        rootBenchmarkId: "",
        credits: 0,
        creditsUsed: 0,
      };
    }
    commit(Mutations.SET_CURRENT_PROJECT, newProject);
    commit(Mutations.SET_PROJECT_LOADING_FALSE);

    dispatch(Actions.GET_PROJECT_AGGREGATES, newProject?.identifier);
    dispatch(Actions.GET_PROJECT_ASSESSMENTS, newProject?.identifier);
    dispatch(Actions.GET_PROJECT_BENCHMARKS, newProject?.identifier);

    return newProject;
  },
  async [Actions.LOAD_PROJECTS]({ commit }, projects: V2Project[]) {
    commit(Mutations.SET_PROJECTS, projects);
  },
  async [Actions.SET_PROJECT_LOADING]({ commit }) {
    commit(Mutations.SET_PROJECT_LOADING);
  },
  async [Actions.SET_PROJECT_LOADING_FALSE]({ commit }) {
    commit(Mutations.SET_PROJECT_LOADING_FALSE);
  },
  async [Actions.GET_LITERALS]({ commit }, { module, version }: { module: string; version: string }) {
    // TODO: Handle MultiLanguages
    const literals = await getResourceByVersion(module, "en", version);
    commit(Mutations.SET_LITERALS, { module, version, literals });

    return literals;
  },
  async [Actions.GET_CANDIDATE_ASSESSMENT_PROJECT]({ commit }, assessmentId: string) {
    const project = await adminService.getCandidateProject(assessmentId);

    commit(Mutations.SET_CURRENT_PROJECT, project);
    commit(Mutations.SET_PROJECT_THEME, {
      projectId: project.projectId,
      theme: project.theme,
    });

    return project;
  },
  async [Actions.GET_ORG_PROJECTS]({ commit, state }) {
    const projects = (await systemAPI.getProjects()).data;
    commit(Mutations.SET_ORG_PROJECTS, projects);

    const currentProject = state.currentProject;
    if (currentProject && currentProject.identifier !== "") {
      const project = projects.find((p) => p.identifier === currentProject.identifier);
      if (project) {
        commit(Mutations.SET_CURRENT_PROJECT, project);
      }
    }

    return projects;
  },
  async [Actions.GET_REPORT]({ commit }, { assessmentId, isCandidate = false }: { assessmentId: string; isCandidate: boolean }) {
    // TODO: Uses assessmentId, should use reportId once we support multi reports
    const report = isCandidate ? await adminService.getCandidateReportData(assessmentId) : await adminService.getCandidateReportData(assessmentId);

    if (report) {
      if (report.version == null) {
        report.version = "1.3"; // TODO: Remove this at some point
      }

      await this.dispatch(`admin/${Actions.GET_LITERALS}`, { module: "admin", version: (report.version ?? "1.0").split(".")[0] });
      commit(Mutations.SET_REPORT, { assessmentId, report });

      return report;
    }

    return null;
  },
  async [Actions.GET_PROJECT_BENCHMARKS]({ commit }, projectId: string) {
    let benchmarks: Benchmark[];
    if (projectId) benchmarks = await adminService.getProjectBenchmarks(projectId);
    else benchmarks = await adminService.getBenchmarks();

    commit(Mutations.SET_PROJECT_BENCHMARKS, { projectId, benchmarks });
    return benchmarks;
  },
  async [Actions.GET_PROJECT_BY_ID]({ state }, projectId: string) {
    const stateProject = state.organizationProjects.find((p) => p.identifier === projectId);

    if (stateProject) {
      return stateProject;
    } else {
      return (await systemAPI.getProjects()).data.find((p) => p.identifier === projectId);
    }
  },
  async [Actions.GET_PROJECT_ASSESSMENTS]({ commit }, projectId: string) {
    const assessments = (await systemAPI.getAssessments({ project: projectId })).data;
    commit(Mutations.SET_PROJECT_ASSESSMENTS, { projectId, assessments });

    return assessments;
  },
  async [Actions.GET_CANDIDATES_ASSESSMENT](_, assessmentId: string) {
    return (await systemAPI.getAssessmentById(assessmentId)).data;
  },
  async [Actions.GET_PROJECT_AGGREGATES]({ commit }, projectId: string | undefined) {
    const aggregates = (await systemAPI.getProjectAggregates({ project_id: projectId })).data;
    commit(Mutations.SET_PROJECT_AGGREGATES, { projectId, aggregates });

    return aggregates;
  },
  [Actions.SAVE_PROJECT_REPORT]: async (_, { projectId, projectReport }: { projectId: string; projectReport: CreateProjectReport }) => {
    const response = await systemAPI.createProjectReport(projectId, projectReport);

    if (response.status !== 201) {
      throw new Error(`Failed to save report: ${JSON.stringify(response.data)}`);
    }

    return response.headers["x-identifier"];
  },
};
