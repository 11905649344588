
import moment from "moment-mini";

import { CanadidateReportInfo, TemplateSchema, TypedVue } from "@/store/types";
import { Component, Watch } from "vue-property-decorator";
import adminService from "@/services/adminService";
import { Assessment, Benchmark, ElraReportData, Project, Resource, Template } from "@/store/admin/adminTypes";

import EditReportComponent from "@/components/admin/EditReportComponent.vue";
import { AxiosError } from "axios";
import { Action } from "vuex-class";
import { Actions } from "@/store/admin/adminActions";
import { VuexModules } from "@/store";
import { emptyResource } from "@/store/emptyModels";
import assessmentService from "@/services/assessmentService";
import { Trait } from "@/lib/consts";

interface SelectItem {
  text: string;
  value: string | null;
}

interface AssessmentSelectItem extends SelectItem {
  hasReport?: boolean;
  isComplete?: boolean;
  name: string;
}

const namespace = VuexModules.ADMIN;
const templates = require.context("@/components/reports/templates", false, /\.html\.tmpl$/); // Uses a method from the webpack require object to get all '.tmpl' files from the templates folder

/*
TODO:
-
*/

@Component({
  components: {
    EditReportComponent,
  },
})
export default class GenerateELRAReport extends TypedVue {
  selectedOrg: string | null = null;
  selectedProject: string | null = null;
  selectedAssessment: string | null = null;
  selectedScoringVersion: string | null = null;
  selectedReportType: string | null = null;
  selectedReportVersion: string | null = null;

  showIncompleteOnly = false;
  generateReport = false;
  orgsLoading = false;
  projectsLoading = false;
  assessmentsLoading = false;
  versionsLoading = false;
  templatesLoading = false;
  generating = false;
  errorMsgs: string[] = [];

  orgs: SelectItem[] = [];
  projects: SelectItem[] = [];
  fullProjects: Project[] = [];
  assessments: AssessmentSelectItem[] = [];
  fullAssessments: Assessment[] = [];
  scoringVersions: string[] = [];
  templates: Template[] = [];
  benchmarks: Benchmark[] = [];

  resource: Resource = emptyResource();
  templateHTML = "";
  reportData: CanadidateReportInfo | null = null;
  publishData: { templateHTML: string; sendEmail: boolean } | null = null;
  publishing = false;

  @Action(Actions.GET_LITERALS, { namespace })
  getLiterals!: ({ module, version }: { module: string; version: string }) => Promise<Resource>;

  @Action(Actions.GET_TEMPLATE, { namespace })
  getTemplate!: (templateName: string) => Promise<TemplateSchema | null>;

  @Action(Actions.SAVE_TEMPLATE, { namespace })
  saveTemplate!: (args: { name: string; originId: string; template: string; isCustom?: boolean }) => Promise<void>;

  // get validScoringVersions(): string[] {
  //   if (this.selectedReportType !== null && this.selectedReportType?.toLocaleLowerCase() === "cpa") {
  //     this.selectedScoringVersion = "v4";
  //     return this.scoringVersions.filter((x) => x === "v4");
  //   } else {
  //     return this.scoringVersions;
  //   }
  // }

  get loading(): boolean {
    return this.orgsLoading || this.projectsLoading || this.assessmentsLoading || this.versionsLoading;
  }

  get filteredAssessments(): SelectItem[] {
    const assessmentFilter = (entry: AssessmentSelectItem) => {
      if (!entry.isComplete) {
        return false;
      }
      return this.showIncompleteOnly ? entry.hasReport === false : true;
    };
    if (this.selectedProject !== null) {
      const results = this.assessments.filter(assessmentFilter);
      if (results) {
        return results;
      }
    }
    return [];
  }

  get selectedAssessmentName() {
    const selected = this.assessments.find((a) => a.value === this.selectedAssessment);
    if (selected) {
      return selected.name;
    }
    return "";
  }

  get reportTypes(): SelectItem[] {
    const versions = this.templates.map((template) => {
      return template.name.split("_")[0];
    });
    return [...new Set(versions)].map((version) => {
      return {
        text: version.toUpperCase(),
        value: version,
      };
    });
  }

  // get reportVersions(): SelectItem[] {
  //   return this.templates
  //     .filter((t) => t.name.split("_")[0] === this.selectedReportType)
  //     .map((template) => {
  //       if (template.isCustom) {
  //         const version = template.name.split("_")[1].split("-")[0];
  //         const created = moment.utc(template.dateCreated).local();
  //         return {
  //           text: `${version} Custom - ${created.format("MMMM Do YYYY, h:mm a")}`,
  //           value: template.name.split("_")[1],
  //         };
  //       }
  //       return {
  //         text: template.name.split("_")[1],
  //         value: template.name.split("_")[1],
  //       };
  //     });
  // }

  get traitClassRanges(): Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }> {
    const rootBenchmarkId = this.fullProjects.find((p) => p.projectId === this.selectedProject)?.rootBenchmarkId;
    const rootBenchamrk = this.benchmarks.find((b) => b.benchmarkId === rootBenchmarkId);
    if (rootBenchamrk && rootBenchamrk.traitCutoffs) {
      return rootBenchamrk.traitCutoffs as Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
    }
    return {} as Record<Trait, { low: number; high: number; lowerlow: number; mean: number; standardDeviation: number }>;
  }

  @Watch("selectedOrg")
  onOrgSelect(newOrg: string): void {
    this.selectedProject = null;
    this.generateReport = false;
    if (newOrg) {
      this.getProjects(newOrg).catch((err: AxiosError) => this.errorMsgs.push(err.message));
    }
  }

  @Watch("selectedProject")
  onProjectSelect(newProj: string): void {
    this.selectedAssessment = null;
    this.generateReport = false;
    if (newProj) {
      this.getAssessments(newProj).catch((err: AxiosError) => this.errorMsgs.push(err.message));
    }
  }

  @Watch("selectedAssessment")
  onAssessmentSelect(newAssessment: string | null): void {
    this.selectedScoringVersion = null;
    this.generateReport = false;
    if (newAssessment) {
      this.getScoringVersions(newAssessment).catch((err: AxiosError) => this.errorMsgs.push(err.message));
      this.getReportVersions(newAssessment).catch((err: AxiosError) => this.errorMsgs.push(err.message));
    }
  }

  // @Watch("selectedScoringVersion")
  // onScoringSelect(): void {
  //   this.selectedReportType = null;
  //   this.generateReport = false;
  // }

  @Watch("selectedReportType")
  onReportTypeSelect(reportType: string): void {
    this.selectedReportVersion = this.getLatestReportVersion(reportType);
    if (reportType === "cpa") {
      this.selectedScoringVersion = "v4";
    } else {
      this.selectedScoringVersion = "v3";
    }
    this.generateReport = false;
  }

  // @Watch("selectedReportVersion")
  // onTemplateSelect(): void {
  //   this.generateReport = false;
  // }

  mounted(): void {
    this.getOrgs().catch((err: AxiosError) => this.errorMsgs.push(err.message));
    // this.getReportVersions().catch((err: AxiosError) => this.errorMsgs.push(err.message));
  }

  getLatestReportVersion(reportType: string) {
    const versions = this.templates
      .filter((t) => t.name.split("_")[0] === reportType && t.isCustom === false)
      .map((template) => {
        return template.name.split("_")[1];
      })
      .sort((a, b) => {
        const aParts = a.split(".");
        const bParts = b.split(".");
        if (aParts[0] === bParts[0]) {
          return parseInt(bParts[1]) - parseInt(aParts[1]);
        }
        return parseInt(bParts[0]) - parseInt(aParts[0]);
      });
    return versions[0];
  }

  async getOrgs(): Promise<void> {
    this.orgsLoading = true;
    const organizations = (await adminService.getOrganizations()).map((org) => {
      return { text: org.displayName, value: org.id };
    });
    this.orgs = organizations;
    this.orgsLoading = false;
  }

  async getProjects(selectedOrg: string): Promise<void> {
    this.projectsLoading = true;

    await adminService.getProjects(selectedOrg, null).then((res) => {
      const projects = res.map((p: Project) => {
        return { value: p.projectId, text: p.name };
      });
      this.projects = projects;
      this.fullProjects = res;
    }),
      (this.projectsLoading = false);
  }

  async getAssessments(selectedProject: string): Promise<void> {
    this.assessmentsLoading = true;
    await adminService.getAssessments(selectedProject).then((res) => {
      this.fullAssessments = res.assessments;
      this.assessments = res.assessments.map((entry) => {
        return {
          value: entry.assessmentId,
          text: `${entry.assessmentId.substring(0, 4)} - ${entry.candidateName}`,
          hasReport: entry.hasReport,
          isComplete: entry.isComplete,
          name: entry.candidateName,
        };
      });
    });
    this.assessmentsLoading = false;
  }

  async getScoringVersions(assessmentId: string): Promise<void> {
    this.versionsLoading = true;
    this.scoringVersions = await adminService.getReportScoringVersions(assessmentId);
    this.versionsLoading = false;
  }

  async getReportVersions(assessmentId: string): Promise<void> {
    this.templatesLoading = true;
    this.templates = await assessmentService.getTemplates(assessmentId);
    this.templatesLoading = false;
  }

  async getBenchmarks(): Promise<void> {
    if (this.selectedAssessment) {
      const projectId = this.fullAssessments.find((a) => a.assessmentId === this.selectedAssessment)?.project;
      this.benchmarks = await adminService.getProjectBenchmarks(projectId as string);
    }
  }

  async getTemplateHTML(templateName: string) {
    const template = templates.keys().filter((t) => t.indexOf(templateName) > -1);
    if (template) {
      if (template.length === 0) {
        const templateInfo = await this.getTemplate(templateName);
        if (templateInfo) {
          return templateInfo.template;
        } else {
          return Promise.reject(`Template ${templateName} not found.`);
        }
      } else if (template.length > 1) {
        return Promise.reject(`${templateName} has multiple matches.`);
      } else {
        return Promise.resolve(templates(template[0]).default);
      }
    } else {
      return Promise.reject("Unknown Error Rendering Report.");
    }
  }

  handleGenerateReport(): void {
    if (this.selectedAssessment && this.selectedReportVersion && this.selectedScoringVersion && this.selectedReportType) {
      this.generating = true;

      const benchmarkRequest = this.getBenchmarks();
      const resourceQuery = this.getLiterals({ module: `report:${this.selectedReportType}`, version: this.selectedReportVersion.split(".")[0] }).then((res) => {
        this.resource = res;
      });
      const dataRequest = adminService.getReportTemplateData(this.selectedAssessment, this.selectedScoringVersion).then((data) => {
        data.reportVersion = this.selectedReportVersion as string;
        data.type = this.selectedReportType as string;
        this.reportData = {
          data,
          id: "",
          readiness: data.readiness,
          type: this.selectedReportType as string,
          version: this.selectedReportVersion as string,
          projectId: "",
        };
        this.reportData.data.candidate_name = this.selectedAssessmentName;
      });
      const templateRequest = this.getTemplateHTML(`${this.selectedReportType}_${this.selectedReportVersion}`).then((res) => {
        this.templateHTML = res;
      });

      Promise.all([resourceQuery, dataRequest, templateRequest, benchmarkRequest])
        .finally(() => {
          this.generating = false;
          this.generateReport = true;
        })
        .catch((err: AxiosError) => {
          if (err.response) {
            if ((err.response.data as any)?.detail) {
              this.errorMsgs.push(`Error: ${(err.response.data as any).detail}`);
            } else {
              this.errorMsgs.push(err.response.data as any);
            }
          } else {
            this.errorMsgs.push(err.message);
          }
        });
    }
  }

  onPublish(data: { templateHTML: string; sendEmail: boolean }): void {
    this.publishData = data;
    this.publishing = true;
    if (data.templateHTML !== this.templateHTML) {
      this.$bvModal.show("confirm-modal");
    } else {
      this.doPublish(this.selectedReportVersion as string);
    }
  }

  createCustomTemplate() {
    this.$bvModal.hide("confirm-modal");
    if (this.publishData && this.selectedAssessment && this.selectedReportVersion) {
      let version = this.selectedReportVersion;
      if (this.selectedReportVersion.includes(":")) {
        version = this.selectedReportVersion.split("-")[0];
      }
      const templateName = `${version}-${this.selectedAssessment}:${Date.now()}`;
      this.saveTemplate({ name: `${this.selectedReportType}_${templateName}`, originId: this.selectedAssessment, template: this.publishData.templateHTML, isCustom: true })
        .then(() => {
          this.doPublish(templateName);
        })
        .catch(() => {
          this.publishData = null;
          this.errorMsgs.push("Error creating custom template");
        });
    }
  }

  doPublish(templateName: string) {
    if (this.publishData && this.selectedAssessment && this.reportData && this.selectedScoringVersion && this.selectedReportType) {
      const reportData = this.reportData.data as ElraReportData;
      reportData.reportVersion = templateName;
      reportData.scoringVersion = this.selectedScoringVersion;
      reportData.type = this.selectedReportType;

      adminService
        .publishReport(this.selectedAssessment, reportData, this.publishData.sendEmail, this.reportData.readiness === "beta")
        .then((result) => {
          if (result.success) {
            alert(`Report published!`);
          } else {
            alert(`Error publishing report: ${result.detail}`);
          }
        })
        .finally(() => {
          this.selectedProject = null;
          this.selectedAssessment = null;
          this.selectedScoringVersion = null;
          this.generateReport = false;
          this.publishData = null;
          this.publishing = false;
          this.$emit("published");
        })
        .catch(() => this.errorMsgs.push("Error publishing report"));
    }
  }

  onCancel() {
    this.publishData = null;
    this.publishing = false;
  }
}
