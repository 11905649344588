
import Vue from "vue";
import ReportRenderer from "@/components/reports/ReportRenderer.vue";
import CompetencyMatrix, { MatrixView } from "@/components/admin/CompetencyMatrix.vue";
import ComparisonRadarChartComponent, { ComparisonAssessmentItem, ComparisonBenchmarkItem, ComparisonChartPreset } from "@/components/admin/ComparisonComponents/ComparisonRadarChartComponent.vue";
import CompetenciesToggleSlideout from "@/components/admin/CompetenciesToggleSlideout.vue";
import CompetenciesListing from "@/components/admin/CompetenciesListing.vue";
import CandidateSearchTable from "@/components/admin/CandidateSearchTable.vue";

import { TypedVue } from "@/store/types";
import { Component, Inject, Prop, Ref, Watch } from "vue-property-decorator";
import { ProjectTheme, ProjectReportData, TeamEffectivenessReportData, V2Project } from "@/store/admin/adminTypes";
import { TRAIT_CLASS_RANGES, Trait, TRAITS } from "@/lib/consts";
import { SchemasCandidateAssessment } from "@/lib/serviceTypes";
import { ColorMap } from "@/lib/charts/HighChartThemes";
import MarkdownIt from "markdown-it";
import { Getter } from "vuex-class";
import { Getters } from "@/store/admin/adminGetters";
import VueI18n from "vue-i18n";

const md = new MarkdownIt({ html: true });
const namespace = "admin";

@Component({
  components: {
    ReportRenderer,
    ComparisonRadarChartComponent,
    CompetencyMatrix,
    CompetenciesToggleSlideout,
    CompetenciesListing,
    CandidateSearchTable,
  },
})
export default class TeamEffectivenessReport extends TypedVue {
  public showNames = true;
  public myArray: any[] = [];
  public observer: IntersectionObserver | null = null;
  public progressAnimator = 0;
  public startAnimation = false;

  public comparisonCandidates: ComparisonAssessmentItem[] = Vue.observable([]);
  public comparisonBenchmarks: ComparisonBenchmarkItem[] = Vue.observable([]);
  public reportData: TeamEffectivenessReportData | null = null;

  public candidatesView = "rankings";
  public candidatesRowsExpanded = false;
  public showCandidatesTableCompetencyBreakdown = false;

  public showOnlyMustHaves = false;
  public matrixView: MatrixView = "top-priority";
  public allowMatrixReorder = false;
  public showMatrixChampions = false;
  public allowedCompetencies: Trait[] = [...TRAITS];
  public selectedPreset: ComparisonChartPreset | null = null;

  public matrixFilterTags: string[] = [];
  public matrixFilterLeadershipStyle: string[] = [];
  public showMatrixFilter = false;
  public matrixFilterText = "";

  @Inject("$i18n") readonly $i18n!: VueI18n;

  @Prop({ required: true })
  readonly report!: ProjectReportData;

  @Prop({ required: true })
  readonly project!: V2Project;

  @Ref("per-person-bars-section")
  readonly perPersonBarsSection!: HTMLElement | undefined;

  @Getter(Getters.GET_CURRENT_PROJECT_ROOT_BENCHMARK_ID, { namespace })
  public rootBenchmarkId!: string;

  @Watch("reportData")
  onReportDataChange() {
    this.$nextTick(() => {
      if (this.perPersonBarsSection) {
        this.observer?.observe(this.perPersonBarsSection);
      } else {
        this.progressAnimator = 100;
      }
    });
  }

  get matrixCacheKey() {
    return `matrix-${this.reportId}`;
  }

  get loading() {
    return this.reportData === null;
  }

  get projectTheme(): ProjectTheme {
    return (this.project?.theme as ProjectTheme) ?? ProjectTheme.DEFAULT_TA;
  }

  get traitClassRanges() {
    return TRAIT_CLASS_RANGES;
  }

  get CompetencySummaries() {
    return Object.keys(this.reportData?.traitPriorities ?? {})
      .filter((key) => (this.showOnlyMustHaves ? (this.reportData?.traitPriorities[key] ?? 0) === 2 : true))
      .map((key) => {
        const passed = (this.reportData?.assessments ?? []).filter((a) => (a.scoring.trait_scores as any)[key] >= TRAIT_CLASS_RANGES[key as Trait].low).length;
        const percent = Math.round((passed / (this.reportData?.assessments.length ?? 1)) * 100);

        return {
          name: this.$t(`Comparison::TraitChart::XAxisLabel::${key}`).toString(),
          passed,
          percent,
          level: percent > 80 ? "high" : percent > 21 ? "medium" : "low",
        };
      })
      .sort((a, b) => b.passed - a.passed);
  }

  get filteredAssessments() {
    return this.reportData?.assessments
      .filter((a) => this.matrixFilterText === "" || a.candidate.name.toLowerCase().includes(this.matrixFilterText.toLowerCase()))
      .filter((x) => (this.matrixFilterTags.length === 0 && this.matrixFilterLeadershipStyle.length === 0) || this.matrixFilterLeadershipStyle.includes(x.scoring.readiness as string));
  }

  get uniqueReadinessLevels() {
    return this.comparisonCandidates
      .map((c) => c.data.readinessScore)
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((v) => ({ name: this.$t(`Report::Readiness::textShort::${v}`), item: v }));
  }

  get reportId() {
    return this.$route.params.id;
  }

  get traitWeights() {
    if (this.reportData?.traitWeights) {
      return this.reportData.traitWeights;
    }

    const weights: Record<string, number> = {};
    for (const trait of TRAITS) {
      weights[trait] = 0;
    }
    return weights;
  }

  get managerAssessment() {
    for (const assessment of this.reportData?.assessments || []) {
      if (this.reportData?.managers?.includes(assessment.identifier)) {
        return assessment;
      }
    }

    return null;
  }

  get candidateAssessments() {
    return this.reportData?.assessments.filter((a) => !this.reportData?.managers?.includes(a.identifier)) || [];
  }

  public presetSelected(preset: ComparisonChartPreset) {
    this.selectedPreset = preset;
  }

  public presetDeSelected() {
    this.selectedPreset = null;
  }

  public toggleShowChampions() {
    this.showMatrixChampions = !this.showMatrixChampions;
  }

  public toggleMatrixReorder() {
    this.allowMatrixReorder = !this.allowMatrixReorder;
  }

  public setMatrixView(value: MatrixView) {
    this.matrixView = value;
  }

  public closeMatrixFilter() {
    this.showMatrixFilter = false;
  }

  public clearAllMatrixFilters() {
    this.matrixFilterLeadershipStyle = [];
    this.matrixFilterTags = [];
  }

  public competencyListUpdated(competencies: string[]) {
    this.allowedCompetencies = [...(competencies as Trait[])];
  }

  async mounted() {
    this.reportData = this.report.data as TeamEffectivenessReportData;
    this.competencyListUpdated([...TRAITS]);
    this.observer = new IntersectionObserver(this.handleScrollIntoView, {
      root: null, // Use the viewport as the root
      rootMargin: "0px", // No margin around the root
      threshold: 0.1, // 10% of the element is visible
    });
    if (this.perPersonBarsSection) {
      this.observer?.observe(this.perPersonBarsSection);
    } else {
      this.progressAnimator = 100;
    }

    this.comparisonBenchmarks = Vue.observable<ComparisonBenchmarkItem[]>(
      this.project.benchmarks?.map(
        (b) =>
          ({
            data: b as any,
            id: b.identifier,
            hidden: false,
            highlighted: false,
            locked: false,
            selected: this.rootBenchmarkId === b.identifier || false,
            color: localStorage.getItem(`report-chartcolor-${b.identifier}`) ?? (b.colour || ColorMap[this.projectTheme].default),
            initialColor: b.colour || ColorMap[this.projectTheme].default,
          } as ComparisonBenchmarkItem),
      ) ?? [],
    );
    this.comparisonCandidates = Vue.observable<ComparisonAssessmentItem[]>(
      this.reportData.assessments.map(
        (c) =>
          ({
            data: { ...(c as unknown as SchemasCandidateAssessment), assessmentId: c.identifier, traitScores: c.scoring.trait_scores, readinessScore: c.scoring.readiness, candidateName: c.candidate.name },
            id: c.identifier,
            hidden: false,
            highlighted: false,
            locked: false,
            selected: false,
            color: localStorage.getItem(`report-chartcolor-${c.identifier}`) ?? ColorMap[this.projectTheme][(c.scoring.readiness as string) ?? "default"],
            initialColor: ColorMap[this.projectTheme][(c.scoring.readiness as string) ?? "default"],
          } as ComparisonAssessmentItem),
      ),
    );
  }

  beforeDestroy() {
    // Disconnect the observer when the component is destroyed
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleScrollIntoView(entries: IntersectionObserverEntry[]) {
    entries.forEach(({ target, isIntersecting }) => {
      if (target === this.perPersonBarsSection && isIntersecting) {
        this.startAnimation = true;
      }
    });
  }

  animateProgressBars() {
    if (this.progressAnimator == 0) {
      const interval = setInterval(() => {
        if (this.progressAnimator < 100) {
          this.progressAnimator++;
        } else {
          clearInterval(interval); // Stop the interval when count reaches 100
        }
      }, 15); // 2 milliseconds per increment
    }
  }

  getTraitPriorities(level: number) {
    return Object.keys(this.reportData?.traitPriorities || {}).filter((trait) => this.reportData?.traitPriorities[trait] === level);
  }

  viewGuide(): void {
    window.open("https://assets.system-3.com/web-assets/report/report-guide.pdf");
  }

  renderMarkdown(text: string) {
    return md.render(text);
  }
}
